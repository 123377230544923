import React, { useRef, useState } from "react";
import { styled } from "styled-components";
import AWS from "aws-sdk";
import { upload_files } from "../../api/auth";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { updateData, updateFormData } from "../Redux/Actions";
import { truncateText } from "../../utils/functions";
import { Alert, Snackbar } from "@mui/material";
import { getExtension } from "../screens/applicationForm/general/functions";

const AttachmentCard = ({ name, index, rawData, setRawData, dataRaw, attachment_description }) => {
  const fileInputRef = useRef(null);
  const [fileData, setFile] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState("");
  const [atachData, setAtachData] = useState();
  const { attachmentData } = useSelector((state) => state.Login.formData);
  const dispatch = useDispatch();
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    const maxSizeInBytes = 2 * 1024 * 1024;
    let fileName = name;
    if (fileSizeInBytes <= maxSizeInBytes) {
      if (selectedFile) {
        const fileType = selectedFile.type;

        // Check if the file type is allowed
        if (
          fileType.startsWith("image/jpeg") ||
          fileType.startsWith("image/jpg") ||
          fileType.startsWith("image/png")
        ) {
          const newFile = new File(
            [selectedFile],
            name + "." + getExtension(selectedFile.name)
          );
          setFile(newFile);

          const url = "application/upload-file/"; // Replace with your API URL
          const formData = new FormData();
          formData.append("file", selectedFile);

          const config = {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          return api.post(url, formData, config).then(async (res) => {
            if (res.status == 1) {
              setImage({
                url: res.filer_url,
                document_type: selectedFile.type,
              });
              setRawData((prev) => [
                ...prev,
                {
                  document_name: name,
                  document_type: selectedFile.type,
                  file_name: fileName,
                  required: true,
                  document_url: res.filer_url,
                  other_attachment: false,
                },
              ]);
            } else {
              setError(true);
              setErrorMessage("the file is not supported");
            }
          });
        } else if (
          fileType.startsWith("application/pdf") ||
          fileType.startsWith("application/msword") ||
          fileType.startsWith("application/vnd.ms-excel")
        ) {
          const newFile = new File(
            [selectedFile],
            name + "." + getExtension(selectedFile.name)
          );
          setFile(newFile);
          const url = "application/upload-file/"; // Replace with your API URL
          const formData = new FormData();
          formData.append("file", selectedFile);

          const config = {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          return api.post(url, formData, config).then(async (res) => {
            if (res.status == 1) {
              setImage({
                url: res.filer_url,
                document_type: selectedFile.type,
              });
              setRawData((prev) => [
                ...prev,
                {
                  document_name: name,
                  document_type: selectedFile.type,
                  file_name: fileName,
                  required: true,
                  document_url: res.filer_url,
                  other_attachment: false,
                },
              ]);
            } else {
              setError(true);
              setErrorMessage("the file is not supported");
            }
          });
        } else {
          setError(true);
          setErrorMessage("Invalid file type");
          setTimeout(() => {
            setError(false);
            setErrorMessage("");
          }, 3000);
        }
      }
    } else {
      setError(true);
      setErrorMessage("File size exceeds the limit (2MB)");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 3000);
    }
  };
  return (
    <Cover progress={progress}>
      <ColumnContainer>
        <h4 style={{maxWidth: "280px" }}>{name}</h4>
        <Left>
          <ImageContainer>
            {image?.document_type == "image/jpeg" ||
              image?.document_type == "image/png" ? (
              <img src={image.url} alt={<i class="ri-file-text-line"></i>} />
            ) : (
              <i class="ri-file-text-line"></i>
            )}
          </ImageContainer>
          <LeftDetails>
            <h6 style={{ color: '#A04646', fontSize: '13px', textAlign: 'left', width: '100%', lineHeight: '1.2', marginBottom: '4px' }}>
              {attachment_description}
            </h6>
            <h6 style={{ padding: '10px 0 20px 0' }}>
              {fileData ? '' : "No file"}
            </h6>
            {/* <h6 style={{color:'#A04646',fontSize:'13px',position:'absolute',left:0}}>*If Son or Daughter age below 15, need a copy of mother residence visa</h6> */}
            {/* <h6>{fileData ? truncateText(fileData.name, 12) : "No file"}</h6> */}
          </LeftDetails>
        </Left>
      </ColumnContainer>
      <Right>
        {fileData ? (
          <p
            onClick={() => {
              const updatedData = rawData.filter((item) => item.document_name !== name);
              setRawData(updatedData);
              setFile();
              setImage();
              setProgress();
              // setRawData();
            }}
          >
            Remove
          </p>
        ) : (
          <InputCover style={{ top: 10 }}>
            <h4 >
              upload <i class="ri-upload-line"></i>
            </h4>
            <InputButton
              type="file"
              accept=".pdf, .doc, .docx, .jpg, .png"
              onChange={handleFileChange}
            />
          </InputCover>
        )}
      </Right>

      {/* {error && <p className='error_message'>{errorMessage}</p>} */}
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" sx={{ width: "100%", zIndex: 1500 }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Cover>
  );
};

export default AttachmentCard;

const ImageContainer = styled.div`
  width: 52px;
  height: 64px;
  min-width: 52px;
  min-height: 64px;
  background: #f4f4f4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #00c0a5;
    font-size: 24px;
  }
  img {
    width: 100%;
    height: 100%;
    
  }
`;
const InputButton = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Cover = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  position: relative;
  transition: 0.4s ease;

  p {
    &.error_message {
      position: absolute;
      bottom: 5px;
      font-size: 12px;
      left: 17%;
    }
  }

  &::after {
    content: "";
    width: ${({ progress }) => progress + "%"};
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #27bf6f70;
    border-radius: 5px;
    transition: 0.4s ease;
  }
`;

const Left = styled.div`
 display: flex;
  gap: 15px;
  align-items: center;
  max-width: 70%; /* Restrict width to prevent overflow */
  position: relative;
`;

const LeftDetails = styled.div`
  width: 100%;
  h4 {
    font-size: 14px;
    line-height: 1.5;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
  h6 {
    color: #9a9a9a;
  }
`;

const Right = styled.div`
  flex-shrink: 0; /* Prevent shrinking */
  max-width: 30%; /* Restrict width */
  text-align: right; /* Align content to the right */
  p {
    color: #ff3e3e;
    font-size: 14px;
    cursor: pointer;
    margin: 0; /* Remove unnecessary margin */
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%; /* Allow full width on small screens */
    text-align: center; /* Center-align for smaller screens */
  }
`;

const InputCover = styled.div`
  width: 114px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #00c0a5;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  @media only screen and (max-width: 480px) {
    width: 84px;
    height: 40px;
  }

  h4 {
    color: #00c0a5;
    font-size: 14px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
