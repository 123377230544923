import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import SubmitButton from "../../general/SubmitButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RtlProvider from "../../general/RtlProvider";
import { useDispatch, useSelector } from "react-redux";
import { submit_attachment, view_attachments } from "../../../api/auth";
import AttachmentCard from "../../general/AttachmentCard";
import ComponentLoader from "../../../utils/loader/ComponentLoader";
import { GradeOutlined } from "@mui/icons-material";
import { GradientButton } from "../../general";
import { clearData, updateData, updateFormData } from "../../Redux/Actions";
import ButtonLoader from "../../../utils/loader/ButtonLoader";
import api from "../../../api";
import { truncateText } from "../../../utils/functions";
import { Alert, Snackbar } from "@mui/material";

const Attachments = () => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [attachmentData, setAttachmentData] = useState([]);
  const state = useSelector((state) => state.Login.formData);
  const [otherAttachment, setOtherAttachment] = useState();
  const [additionalNote, setAditionalNote] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    view_attachments(state.sub_service_id).then((res) => {
      if (res.status == 1) {
        setLoading(false);
        // dispatch(clearData())
        setAditionalNote(res.additional_note);
        setAttachmentData(res.data);
        dispatch(updateFormData({ attachmentData: res.data }));
      }
    });
  }, []);
  const [rawData, setRawData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dataRaw = {
    document_name: null,
    document_type: "jpg",
    required: true,
    document_url: null,
  };

  const requiredAttachments = attachmentData.filter((obj) => obj.required);
  console.log(requiredAttachments, "attachments");

  const areAllAttachmentsPresent = (required, data) => {
    // Check if every required attachment exists in the rawData array
    return required.every(req => 
      data.some(item => item.document_name === req.required_attachment)
    );
  };
  const allRequiredExistInRawData = areAllAttachmentsPresent(requiredAttachments, rawData)

  const handleSubmit = () => {
    console.log("Required Attachments:", requiredAttachments);
    console.log("Raw Data:", rawData);
  
    if (allRequiredExistInRawData) {
      console.log("All required attachments exist!");
    } else {
      console.log("Missing required attachments!");
    }

    if (allRequiredExistInRawData) {
      setButtonLoading(true);
      submit_attachment(
        state.sub_service_id,
        state.application_id,
        rawData
      ).then((res) => {
        if (res.status === 1) {
          setButtonLoading(false);
          const keyToRemove = "formData";
          const existingDataString = localStorage.getItem("Login");
          if (existingDataString) {
            const existingData = JSON.parse(existingDataString);
            if (existingData[keyToRemove]) {
              delete existingData[keyToRemove];
              localStorage.setItem("Login", JSON.stringify(existingData));
            }
          }
          dispatch(clearData());

          navigate(`/application/${res.application_id}`);
        } else {
          setError(true);
          setButtonLoading(false);
          setErrorMessage(res.message);
          setTimeout(() => {
            setError(false);
            setErrorMessage("");
          }, 3000);
        }
      });
    } else {
      setError(true);
      setErrorMessage("Required Documents to be attached!");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 3000);
    }
  };
  const [file, setFile] = useState();
  const [progress, setProgress] = useState();
  const { t } = useTranslation();
  const handleOther = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    const maxSizeInBytes = 2 * 1024 * 1024;
    let fileName = selectedFile.name;
    if (fileSizeInBytes <= maxSizeInBytes) {
      if (selectedFile) {
        const fileType = selectedFile.type;

        // Check if the file type is allowed
        if (
          fileType.startsWith("image/jpeg") ||
          fileType.startsWith("image/jpg") ||
          fileType.startsWith("image/png")
        ) {
          // File is a JPG image
          setFile(selectedFile);
        } else if (
          fileType.startsWith("application/pdf") ||
          fileType.startsWith("application/msword") ||
          fileType.startsWith("application/vnd.ms-excel")
        ) {
          // File is a document
          setFile(selectedFile);
        } else {
          setError(true);
          setErrorMessage("Invalid file type");
          setTimeout(() => {
            setError(false);
            setErrorMessage("");
          }, 3000);
          //   return;
        }
      } else {
        // setError(true)
        // setErrorMessage("attachment is required")
      }

      const url = "application/upload-file/"; // Replace with your API URL
      const formData = new FormData();
      formData.append("file", selectedFile);

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      return api.post(url, formData, config).then(async (res) => {
        setRawData((prev) => [
          ...prev,
          {
            document_name: selectedFile.name,
            document_type: selectedFile.type,
            file_name: fileName,
            required: true,
            document_url: res.filer_url,
            other_attachment: true,
          },
        ]);
      });
    } else {
      setError(true);
      setErrorMessage("File size exceeds the limit (2MB)");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 3000);
    }
  };
  const handleRemove = (itemToRemove) => {
    // Filter out the item to remove from the filteredData
    const updatedData = rawData.filter((item) => item !== itemToRemove);

    // Update the filteredData state with the updated array
    setRawData(updatedData);
  };
  return (
    <Cover>
      {loading ? (
        <ComponentLoader />
      ) : (
        <Container>
          {(additionalNote?.length > 0) && <NoteContainer>
            <h5>Note</h5>
            <p>
              {additionalNote?.map((item)=>(
                item
              ))}
              {/* *Applicant should be inside UAE for emirates id application
              submission<br/> **Only babies who were born inside the UAE and have not
              exited the country since birth are eligible for the New Born
              Category<br/> ***Passport validity should be 6 months */}
            </p>
          </NoteContainer>}
          <Top>
              <Left>
                <h4>Required Documents</h4>
                {/* <h6>{`${
                  rawData.filter(
                    (document) => document.other_attachment === true
                  ).length
                } files`}</h6> */}
              </Left>
              {/* <Right>
                <UploadButton>
                  <input type="file" accept=".pdf, .doc, .docx, .jpg, .png" onChange={(e) => handleOther(e)} />
                  upload <i class="ri-upload-line"></i>
                </UploadButton>
              </Right> */}
            </Top>
          {attachmentData
            ?.filter((data) => data.required)
            .map((item, index) => (
              // <h1></h1>
              <AttachmentCard
                name={item.required_attachment}
                index={index}
                attachmentData={attachmentData[0]}
                setAttachmentData={setAttachmentData}
                rawData={rawData}
                setRawData={setRawData}
                // attachment_description={item.attachment_description}
                dataRaw={dataRaw}
              />
            ))}
          {(attachmentData
              ?.filter((data) => !data.required).length > 0 )&& <OtherAttachmentSect>
            <Top>
              <Left>
                <h4>Other Documents</h4>
                {/* <h6>{`${
                  rawData.filter(
                    (document) => document.other_attachment === true
                  ).length
                } files`}</h6> */}
              </Left>
              {/* <Right>
                <UploadButton>
                  <input type="file" accept=".pdf, .doc, .docx, .jpg, .png" onChange={(e) => handleOther(e)} />
                  upload <i class="ri-upload-line"></i>
                </UploadButton>
              </Right> */}
            </Top>
            {attachmentData
              ?.filter((data) => !data.required)
              .map((item, index) => (
                // <h1></h1>
                <AttachmentCard
                  name={item.required_attachment}
                  index={index}
                  attachmentData={attachmentData[0]}
                  setAttachmentData={setAttachmentData}
                  rawData={rawData}
                  setRawData={setRawData}
                  attachment_description={item.attachment_description}
                  dataRaw={dataRaw}
               
                />
              ))}
          </OtherAttachmentSect>}
          <OtherAttachmentSect>
            <Top>
              <Left>
                <h4>More Documents</h4>
                <h6>{`${
                  rawData.filter(
                    (document) => document.other_attachment === true
                  ).length
                } files`}</h6>
              </Left>
              <Right>
                <UploadButton>
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .jpg, .png"
                    onChange={(e) => handleOther(e)}
                  />
                  upload <i class="ri-upload-line"></i>
                </UploadButton>
              </Right>
            </Top>
          </OtherAttachmentSect>
          <OtherAttahmentList>
            {rawData
              .filter((document) => document.other_attachment === true)
              .map((item) => (
                <OtherAttachmentSect>
                  <Top>
                    <Left2>
                      <ImageContainer>
                        {item.document_type == "image/jpeg" ||
                        item.document_type == "image/png" ? (
                          <img
                            src={item.document_url}
                            alt={<i class="ri-file-text-line"></i>}
                          />
                        ) : (
                          <i class="ri-file-text-line"></i>
                        )}
                      </ImageContainer>
                      <h4>{truncateText(item.document_name, 14)}</h4>
                      {/* <h6>0 files</h6> */}
                    </Left2>
                    <Right>
                      {/* <UploadButton><input type="file" onChange={(e)=>handleOther(e)}/>upload <i class="ri-upload-line"></i></UploadButton> */}
                      <p onClick={() => handleRemove(item)}>Remove</p>
                    </Right>
                  </Top>
                </OtherAttachmentSect>
              ))}
          </OtherAttahmentList>
          <ButtonCover>
            {/* {error && <p className="error_message">{errorMessage}</p>} */}
            <GradientButton
              width="180px"
              radius="5px"
              active={true}
              onClick={() => handleSubmit()}
            >
              {buttonLoading ? <ButtonLoader /> : "SUBMIT REQUEST"}
            </GradientButton>
          </ButtonCover>
        </Container>
      )}
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className="toast_error"
        >
          <Alert
            severity="error"
            sx={{ width: "500px", zIndex: 1500, fontSize: 18 }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Cover>
  );
};

export default Attachments;

const Cover = styled.div`
  padding: 20px;
  background: #f7fcf9;
  border-radius: 40px;
  @media only screen and (max-width: 480px) {
    padding: inherit;
  }
`;
const Container = styled.div`
  width: 40%;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
  margin: 0 auto;
  @media only screen and (max-width: 980px) {
    width: 80%;
  }
  @media only screen and (max-width: 640px) {
    width: 80%;
  }
  @media only screen and (max-width: 540px) {
    width: 80%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    padding: 0px;
  }
`;
const ContainerDrop = styled.div`
  //   width: 300px;
  height: 200px;
  border: 2px dashed #9a9a9a;
  border-radius: 12px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div {
    &.inside_drop {
      @media only screen and (max-width: 420px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 360px) {
        font-size: 12px;
      }
    }
  }
`;

const UploadArea = styled.input`
  display: none;
`;
const ButtonUpload = styled.div`
  width: 150px;
  height: 35px;
  border: 1px solid #000;
  border-radius: 35px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;
const Uploads = styled.div`
  padding: 10px 0px;
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
    }
  }
`;
const ImagePreview = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 10px;
`;
const FileImage = styled.div`
  width: 50px;
  height: 50px;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #00c0a5;
`;
const UploadLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`;
const UploadRight = styled.div`
  cursor: pointer;
  i {
    color: #9a9a9a;
  }
`;
const ButtonCover = styled.div`
  padding-top: 20px;
`;
const OtherAttachmentSect = styled.div`
  padding: 15px 0px;
  @media only screen and (max-width: 480px) {
    padding: 15px 0px;
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Left = styled.div`
  /* display:flex;
align-items:center;
gap:10px; */
  h4 {
    font-size: 16px;
    font-weight: 600;
  }
  h6 {
    color: #9a9a9a;
  }
`;
const Left2 = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  h4 {
    font-size: 16px;
    font-weight: 600;
  }
  h6 {
    color: #9a9a9a;
  }
`;
const Right = styled.div`
  color: #ff3e3e;
  cursor: pointer;
`;
const UploadButton = styled.div`
  width: 114px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #00c0a5;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00c0a5;
  position: relative;
  @media only screen and (max-width: 480px) {
    width: 84px;
    height: 40px;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    opacity: 0;
  }
    margin-right: 9px;
`;
const OtherAttahmentList = styled.div``;
const ImageContainer = styled.div`
  width: 52px;
  height: 64px;
  background: #f4f4f4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #00c0a5;
    font-size: 24px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
const NoteContainer = styled.div`
  padding: 20px;
  background: #fffafa;
  border-radius: 13px;
  border: 1px solid #ffc9c9;
  margin-bottom:20px;
  h5 {
    font-size: 16px;
    color: #dd1818;
    margin-bottom:20px;
  }
  p{
    font-size:12px;
    margin-bottom:10px;
  }
`;
