import { useSelector } from "react-redux";
import api from "..";

const local = JSON.parse(localStorage.getItem('state'));

let token = local?.Login.login.data.access
let refresh = local?.Login.login.data.refresh

export const getServices = () => {
    return api.get('application/view-service/');
}
export const refreshToken =() => {
    return api.post(`accounts/token/refresh/`,{refresh : JSON.parse(localStorage.getItem('state')).Login.login.data.refresh})
}
export const getSubservice = (pk) => {
    return api.get(`application/view-subservice/${pk}/`)
}
export const getServiceForm = (pk)=>{
    return api.get(`application/view-service-form/${pk}/`)
}
export const sendOtp=(phone,phone_code)=>{
    return api.post('accounts/send-otp/',{'phone':phone,'phone_code':phone_code})
}
export const verifyOtp=(phone,phone_code,otp,name)=>{
    return api.post('accounts/verify-otp/',{'phone':phone,'phone_code':phone_code,otp:otp,'first_name':name})
}
export const formSubmission = (formData,form_id,sub_service_id,token) => {
    return api.post(`application/user-application/${form_id}/`,
    {formData},{params:{sub_service_id:sub_service_id}})
}
export const view_attachments = (sub_service_id)=>{
    return api.get(`application/view-attachment/${sub_service_id}/`)
}
// export const upload_files = () => {
//     return api.post('application/upload-file/')
// }
export const user_applications = ({view,currentPage}) => {
    return api.get(`application/user-application-view/`,{
        params: {
            page: currentPage,
            limit: view
        }
    }
    )
}
export const application_details = (pk) => {
    return api.get(`application/user-application-detail/${pk}/`)
}
export const submit_attachment = (pk,application_pk,attachmentData) => {
    return api.post(`application/user-file-upload/${pk}/`,{attachmentData:attachmentData},{params:{application_pk:application_pk}})
}
export const get_application_status=(pk)=>{
    return api.get(`application/application-log-view/${pk}/`)
}
export const reUpload = (pk,rawData) =>{
    return api.post(`application/document-reupload/${pk}/`,{
        // "document_name":rawData.document_name,
        // "document_type":rawData.document_type,
        // "required":true,
        // "document_url":rawData.document_url,
        // "other_attachment":rawData.other_attachment
        rawData
    })
}
export const editApplication = (pk,formData)=>{
    return api.post(`application/edit-userapplication/${pk}/`,{formData})
}
export const searchUser = (code,phone) => {
    return api.post(`accounts/check-username/`,{phone_code:code,phone:phone})
}