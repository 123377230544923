import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import api from "../../../api";
import {
  reUpload,
  submit_attachment,
  view_attachments,
} from "../../../api/auth";
import EditFormModal from "./includes/modals/EditFormModal";
import { truncateText } from "../../../utils/functions";
import { Alert, Snackbar } from "@mui/material";

const ApplicationDetail = ({ datas, changed, setChanged, setEditChanged }) => {
  const [progress, setProgress] = useState("");
  const [file, setFile] = useState();
  const [rawData, setRawData] = useState();
  const [editModal, setEditModal] = useState(false);
  const [open, setOpen] = React.useState(false);

  const formData = useSelector((state) => state.Login.formData);
  const handleClose = (event) => {
    setOpen(false);
  };
  // const handleReupload = async (event,item) => {
  //   const selectedFile = event.target.files[0];
  //   let fileName = selectedFile.name
  //   if (selectedFile) {
  //     const fileType = selectedFile.type;

  //     // Check if the file type is allowed
  //     if (fileType.startsWith('image/jpeg') || fileType.startsWith('image/jpg')) {
  //       // File is a JPG image
  //       setFile(selectedFile);
  //     } else if (
  //       fileType.startsWith('application/pdf') ||
  //       fileType.startsWith('application/msword') ||
  //       fileType.startsWith('application/vnd.ms-excel')
  //     ) {
  //       setFile(selectedFile);
  //     } else {
  //       return;
  //     }
  //   } else {
  //     return;
  //   }
  //   const url = 'application/upload-file/';
  //   const formData = new FormData();
  //   formData.append('file', selectedFile);

  //   const config = {
  //       onUploadProgress: (progressEvent) => {
  //           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //           setProgress(percentCompleted);
  //       },
  //       headers: {
  //           'Content-Type': 'multipart/form-data',
  //       },
  //   };

  //   return api.post(url, formData, config).then(async(res)=>{
  //     console.log("hello world___");
  //       //  setRawData({
  //       //     "document_name": item.document_name,
  //       //     "document_type": selectedFile.type,
  //       //     "file_name":fileName,
  //       //     "required": true,
  //       //     "document_url": res.filer_url,
  //       //     "other_attachment":false,
  //       // })
  //       const newData = {
  //           "document_name": item.document_name,
  //           "document_type": selectedFile.type,
  //           "file_name":fileName,
  //           "required": true,
  //           "document_url": res.filer_url,
  //           "other_attachment":false,
  //       }
  //       console.log(newData,"rawData______________");
  //       await reUpload(item.id,newData).then((res)=>{
  //         setChanged((prev)=>!prev)
  //       })
  //   })
  // }
  const [errorMessage, setErrorMessage] = useState();

  const myDivRef = useRef(null);
  useEffect(() => {

    if (changed === true) {
      myDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [changed]);
  const handleReupload = async (event, item, other) => {
    const selectedFile = event.target.files[0];
    let fileName = selectedFile.name;
    if (selectedFile) {
      const fileType = selectedFile.type;

      // Check if the file type is allowed
      if (
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/jpg") ||
        fileType.startsWith("image/png")
      ) {
        // File is a JPG image
        setFile(selectedFile);
      } else if (
        fileType.startsWith("application/pdf") ||
        fileType.startsWith("application/msword") ||
        fileType.startsWith("application/vnd.ms-excel")
      ) {
        setFile(selectedFile);
      } else {
        setOpen(true);
        // setErrorMessage("Invalid file type");
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
    } else {
      return;
    }
    const url = "application/upload-file/";
    const formData = new FormData();
    formData.append("file", selectedFile);

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return api.post(url, formData, config).then(async (response) => {
      //  setRawData({
      //     "document_name": item.document_name,
      //     "document_type": selectedFile.type,
      //     "file_name":fileName,
      //     "required": true,
      //     "document_url": res.filer_url,
      //     "other_attachment":false,
      // })
      const newData = {
        document_name: selectedFile.name,
        document_type: selectedFile.type,
        file_name: fileName,
        required: true,
        document_url: response.filer_url,
        other_attachment: other,
      };
      await reUpload(item.id, newData).then((res) => {
        // return res.filer_url
        setChanged((prev)=>!prev);
      });
    });
  };
  const [error, setError] = useState();

  const handleOther = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      // No file selected
      setOpen(true);
      setTimeout(() => setOpen(false), 3000);
      return;
    }
  
    const fileSizeInBytes = selectedFile.size;
    const maxSizeInBytes = 2 * 1024 * 1024;
    if (fileSizeInBytes > maxSizeInBytes) {
      setError(true);
      setErrorMessage("File size exceeds the limit (2MB)");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 3000);
      return;
    }
  
    const fileType = selectedFile.type;
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const allowedDocumentTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
    ];
  
    if (
      ![...allowedImageTypes, ...allowedDocumentTypes].includes(fileType)
    ) {
      setOpen(true);
      setTimeout(() => setOpen(false), 3000);
      return;
    }
  
    const url = "application/upload-file/"; // Replace with your API URL
    const formData = new FormData();
    formData.append("file", selectedFile);
  
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
      headers: { "Content-Type": "multipart/form-data" },
    };
  
    try {
      const res = await api.post(url, formData, config);
      const otherData = [
        {
          document_name: selectedFile.name,
          document_type: fileType,
          file_name: selectedFile.name,
          required: true,
          document_url: res.filer_url,
          other_attachment: true,
        },
      ];
      const response = await submit_attachment(
        datas.sub_service_id,
        datas.user_application_id,
        otherData
      );
  
      if (response.status === 1) {
        setChanged((prev) => !prev);
      }
    } catch (error) {
      setOpen(true);
      setTimeout(() => setOpen(false), 3000);
    }
  };
  

  const handleEditButton = () => {
    if (datas?.editable_true) {
      setEditModal((prev) => !prev);
    }
  };
  const [attachmentData, setAtachmentData] = useState();
  const state = useSelector((state) => state.Login.formData);

  // useEffect(()=>{
  //   if(datas?.attachments?.length < 1){
  //     view_attachments(state.sub_service_id).then((res)=>{
  //       if (res.status==1){
  //           // setLoading(false)
  //           // dispatch(clearData())
  //           setAtachmentData(res.data);
  //           // dispatch(updateFormData({"attachmentData":res.data}))
  //       }
  //   })
  //   }
  // },[])
  return (
    <>
      <Cover>
        <h4>Application Details</h4>
        <ApplicationTop>
          <CoverData>
            <MutedText>Application ID</MutedText>
            <DataText>{datas?.application_id}</DataText>
          </CoverData>
          <ContainerCover>
            <ContentLeft>
              <MutedText>Type of Application</MutedText>
              <DataText>{datas?.type_of_application}</DataText>
            </ContentLeft>
            {datas?.editable_true && datas?.application_status_id !== 5 && (
              <EditButton
                onClick={() => handleEditButton()}
                edit={datas?.editable_true}
              >
                Edit Details
              </EditButton>
            )}
            {/* <EditButton onClick={()=>setEditModal((prev)=>!prev)}>Edit Details</EditButton> */}
          </ContainerCover>
        </ApplicationTop>
        <FlexApplication>
          {datas?.fields?.map((fieldData, index) => {
            const fieldKey = Object.keys(fieldData)[0];
            const { field_type, label, validations, value, choices, phone_code } =
              fieldData[fieldKey];
            if (fieldKey == "whatsapp_phone_code") {
              return null;
            } else if (fieldKey == "sponsor_phone_code") {
              return null;
            } else if ((fieldKey == "sponsor_mobile_no" || fieldKey == "whatsapp_no") && value !== null) {
              return (
                <ContentWrap>
                  <MutedText>{label}</MutedText>
                  <DataText>{`${phone_code}${value}`}</DataText>
                </ContentWrap>
              );
            }else if (fieldKey == "comment") {
              return (
                <ContentWrap className="comment">
                  <MutedText>{label}</MutedText>
                  <DataText>{value}</DataText>
                </ContentWrap>
              );
            } else {
              return (
                <ContentWrap>
                  <MutedText>{label}</MutedText>
                  <DataText>{value}</DataText>
                </ContentWrap>
              );
            }
          })}
        </FlexApplication>
        <AttachmentStatus ref={myDivRef}>
          <h5>Attachments</h5>
          {datas?.attachments
            ?.filter((data) => data.other_document == false)
            .map((item) => (
              <FileWidget>
                <CoverData2>
                  <Left
                    onClick={() => window.open(item.document_url, "_blank")}
                  >
                    {item.document_type == "image/jpeg" ||
                    item.document_type == "image/png" ? (
                      <img src={item.document_url} />
                    ) : (
                      <i class="ri-file-text-line"></i>
                    )}
                    {/* <i class="ri-file-text-line"></i> */}
                  </Left>
                  <DesBottom>
                    <h5>{truncateText(item?.document_name, 23)}</h5>
                    <h6>{item?.file_name}</h6>
                  </DesBottom>
                </CoverData2>
                <UploadCover isEditable={datas?.editable_true && datas?.application_status_id !== 5}>
                  {datas?.editable_true && datas?.application_status_id !== 5 ? <Reupload
                    type="file"
                    accept=".pdf, .doc, .docx, .jpg, .png"
                    onChange={(e) => {
                      handleReupload(e, item, false);
                    }}
                  /> : <></>}
                  <p>Reupload</p>
                </UploadCover>
              </FileWidget>
            ))}
          <Other>
            <OtherLeft>
              <h5>Other Attachments</h5>
              <h6>
                {
                  datas?.attachments?.filter(
                    (data) => data.other_document == true
                  ).length
                }{" "}
                files
              </h6>
            </OtherLeft>
            <OtherRight>
            {datas?.application_status_id !== 5 ? <UploadButton isEditable={datas?.application_status_id !== 5}>
                Upload More<i class="ri-arrow-up-line"></i>
              <Reupload
                  type="file"
                  accept=".pdf, .doc, .docx, .jpg, .png"
                  onChange={(e) => {
                    handleOther(e);
                  }}
                />
              </UploadButton>
              : <></>}
            </OtherRight>
          </Other>
          {datas?.attachments
            ?.filter((data) => data.other_document == true)
            .map((item) => (
              <FileWidget>
                <CoverData2>
                  <Left
                    onClick={() => window.open(item.document_url, "_blank")}
                  >
                    {item.document_type == "image/jpeg" ||
                    item.document_type == "image/jpg" ? (
                      <img src={item.document_url} />
                    ) : item.document_type == "image/png" ? (
                      <img src={item.document_url} />
                    ) : (
                      <i class="ri-file-text-line"></i>
                    )}
                    {/* <i class="ri-file-text-line"></i> */}
                  </Left>
                  <DesBottom>
                    <h5>{truncateText(item?.document_name, 23)}</h5>
                    <h6>{item?.file_name}</h6>
                  </DesBottom>
                </CoverData2>
                <UploadCover isEditable={datas?.editable_true && datas?.application_status_id !== 5}>
                {datas?.editable_true && datas?.application_status_id !== 5 ? 
                  <Reupload
                    type="file"
                    accept=".pdf, .doc, .docx, .jpg, .png"
                    onChange={(e) => {
                        handleReupload(e, item, true);
                    }}
                  />: <></>}
                  <p>Reupload</p>
                </UploadCover>
              </FileWidget>
            ))}
        </AttachmentStatus>
        {editModal && (
          <EditFormModal
            datas={datas?.fields}
            application_id={datas?.user_application_id}
            setEditModal={setEditModal}
            setChanged={setChanged}
            setEditChanged={setEditChanged}
          />
        )}
      </Cover>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", zIndex: 1500 }}
        >
          Invalid file format!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApplicationDetail;

const Cover = styled.div`
  width: 60%;
  padding: 0px 50px 0px 0px;
  border-right: 1px solid #dbdbdb;
  @media only screen and (max-width: 764px) {
    width: 100%;
    border-right: inherit;
  }
  @media only screen and (max-width: 480px) {
    padding: 0px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: "poppins_medium";
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
`;
const ApplicationTop = styled.div`
  padding: 15px 0px;
  border-bottom: 1px solid #dbdbdb;
  @media only screen and (max-width: 764px) {
    border-bottom: inherit;
  }
`;
const MutedText = styled.h5`
  color: #838383;
  font-size: 16px;
  font-family: "poppins_medium";
  margin-bottom: 10px;
  @media only screen and (max-width: 980px) {
    font-size: 12px;
  }
`;
const DataText = styled.div`
  color: #000000;
  font-size: 16px;
  line-height:1.5rem;
  font-family: "poppins_medium";

  @media only screen and (max-width: 980px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 10px;
  }
`;
const CoverData = styled.div`
  margin-bottom: 15px;
`;
const FlexApplication = styled.div`
  padding: 15px 0px 30px 0px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
`;
const ContentWrap = styled.div`
  width: 30%;
  &.comment {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    width: 47%;
  }
  @media only screen and (max-width: 360px) {
    width: 40%;
  }
`;
const AttachmentStatus = styled.div`
  padding: 20px 0px;
  h5 {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: "poppins_medium";
  }
`;
const FileWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px 0px;
  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
      font-family: "poppins_regular";
    }
  }
`;
const Left = styled.div`
  width: 50px;
  height: 50px;
  background: #f4f4f4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    object-fit: contain;
  }
  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
  i {
    font-size: 18px;
    color: #00c0a5;
    @media only screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
`;
const DesBottom = styled.div``;
const Reupload = styled.input`
  background: #c7c7c731;
  padding: 8px;
  font-size: 13px;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
`;
const CoverData2 = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
const UploadCover = styled.div`
  width: 123px;
  height: 40px;
  background: ${(props) => (props.isEditable ? '#c7c7c731' : '#f0f0f0')};
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props) => (props.isEditable ? 'auto' : 'none')};
  opacity: ${(props) => (props.isEditable ? '1' : '0.5')};
  p {
    font-size: 14px;
  }
`;

const ContainerCover = styled.div`
  display: flex;
  j\align-items: center;
  justify-content: space-between;
`;
const ContentLeft = styled.div`
  width:60%;
`;
const EditButton = styled.div`
  width: 160px;
  height: 50px;
  color: #00c0a5;
  display: flex;
  align-items: center;
  text-wrap:nowrap;
  white-space:nowrap;
  justify-content: center;
  /* cursor:not-allowed; */
  cursor: ${({ edit }) => (edit ? "pointer" : "not-allowed")};
  /* UI Properties */
  background: #2dbf6622 0% 0% no-repeat padding-box;
  border-radius: 32px;
  opacity: 1;
  @media only screen and (max-width: 480px) {
    width: 130px;
    height: 40px;
    font-size: 14px;
  }
`;
const Other = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
`;
const OtherLeft = styled.div`
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: inherit;
    line-height: 1.5rem;
  }
`;
const OtherRight = styled.div``;
const UploadButton = styled.div`
  width: 155px;
  gap: 5px;
  position: relative;
  height: 40px;
  /* UI Properties */
  background: #1a86e3 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.isEditable ? 'auto' : 'none')};
  opacity: ${(props) => (props.isEditable ? '1' : '0.5')};
`;
