import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import InputBox from "../../general/InputBox";
import PhoneInput from "../../general/PhoneInput";
import DropDown from "../../general/DropDown";
import TextInput from "../../general/TextInput";
import PhoneNumberModal from "./includes/modals/PhoneNumberModal";
import OtpModal from "./includes/modals/OtpModal";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  updateData,
  updateFormData,
} from "../../Redux/Actions/Login";
import { useTranslation } from "react-i18next";
import { emailValidation } from "./general/emailValidation";
import { formSubmission, getServiceForm } from "../../../api/auth";
import ComponentLoader from "../../../utils/loader/ComponentLoader";
import ButtonLoader from "../../../utils/loader/ButtonLoader";
import { clearFormData } from "../../Redux/Reducers/Login/Helper";
import { Alert, Snackbar } from "@mui/material";

const PersonalDetails = () => {
  const [phonemodal, setPhonemodal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const [phoneCode, setPhoneCode] = useState("971");

  const { isOtpVerified } = useSelector((state) => state.Login.formData);
  const { phone, phone_code } = useSelector((state) => state.Login.login.data);
  const dataState = useSelector((state) => state.Login);
  const [fieldErrors, setFieldErrors] = useState({});
  // const isFormComplete = () => {
  //   let isComplete = true;

  //   formData.forEach((fieldData) => {
  //     const fieldName = Object.keys(fieldData)[0];
  //     const { value } = fieldData[fieldName];
  //     if (!value || value.toString().trim() === '') {
  //       isComplete = false;
  //     }if (fieldData[fieldName].field_type === "email"){
  //       if (!emailValidation(value)){
  //         isComplete = false;
  //       }
  //     }
  //   });

  //   return isComplete;
  // };

  const isFormComplete = () => {
    let isComplete = true;

    formData.forEach((fieldData) => {
      const fieldName = Object.keys(fieldData)[0];
      const { value, validations } = fieldData[fieldName];

      if (validations.required === "true") {
        // Check if a required field is empty or doesn't have a valid value
        if (!value || value.toString().trim() === "") {
          isComplete = false;
        } else if (
          fieldData[fieldName].field_type === "email" &&
          !emailValidation(value)
        ) {
          isComplete = false;
        }
      }
    });

    return isComplete;
  };
  // const handleFormValidation = () => {
  //   let newFieldErrors = {};

  //   formData.forEach((fieldData) => {
  //     const fieldName = Object.keys(fieldData)[0];
  //     const { value, label } = fieldData[fieldName];

  //     if (!value || value.toString().trim() === '') {
  //       newFieldErrors[fieldName] = `${label} is required.`;
  //     } else if (fieldData[fieldName].field_type === 'email' && !emailValidation(value)) {
  //       newFieldErrors[fieldName] = `Please enter a valid email address.`;
  //     }
  //     else {
  //       // No need to clear the error message here
  //       // newFieldErrors[fieldName] = '';
  //     }
  //   });

  //   setFieldErrors(newFieldErrors);
  // };

  const handleFormValidation = () => {
    let newFieldErrors = {};

    formData.forEach((fieldData) => {
      const fieldName = Object.keys(fieldData)[0];
      const { value, label, validations } = fieldData[fieldName];

      // Check if the field is required and handle validation accordingly
      if (
        validations &&
        validations.required === "true" &&
        (!value || value.toString().trim() === "")
      ) {
        newFieldErrors[fieldName] = `${label} is required.`;
      } else if (
        validations &&
        validations.email === "true" &&
        !emailValidation(value)
      ) {
        newFieldErrors[fieldName] = `Please enter a valid email address.`;
      } else {
        // Clear the error message if the field is not required or validation passes
        newFieldErrors[fieldName] = "";
      }
    });

    setFieldErrors(newFieldErrors);
  };
  // const [error,setError]=useState();
  //   const handleSubmit = () => {
  //     dispatch(
  //       updateFormData({
  //         form_field: formData,
  //       })
  //     );
  //     if (!isFormComplete()) {
  //       handleFormValidation();
  //       setError(true)
  //     }else{
  //       setError(false)
  //       setButtonLoading(true);

  //     if (isOtpVerified) {

  //       formSubmission(
  //         formData,
  //         dataState.formData.form_id,
  //         dataState.formData.sub_service_id
  //       ).then((res) => {
  //         if (res.status === 1) {
  //           dispatch(
  //             updateFormData({
  //               application_id: res.application_id,
  //             })
  //           );
  //           dispatch(
  //             updateData({ isPersonal: true, currentRoute: "Attachments" })
  //           );
  //           setButtonLoading(false);
  //         }else{
  //           setButtonLoading(false)
  //           setError(true)
  //           setFormError(res.message)
  //           setTimeout(() => {
  //             setError(false)
  //             setFormError('')
  //           }, 3000);
  //         }
  //       });
  //     } else {
  //       dispatch(
  //         updateFormData({
  //           form_field: formData,
  //         })
  //       );
  //       setButtonLoading(false);
  //       setPhonemodal(true);
  //     }
  //   }
  //   };
  //   const handleInputChange = (fieldName, value,phoneNumb) => {
  //     if (error){
  //       handleFormValidation()
  //     }
  //     if (phoneNumb){
  //       const numbersOnly = value.replace(/[^0-9]/g, '');
  //       if(numbersOnly.length < 15){
  //         setFormData((prevFormData) => {
  //           const updatedFormData = prevFormData.map((inputObj) => {
  //             const inputKey = Object.keys(inputObj)[0];
  //             if (inputKey === fieldName) {
  //               return {
  //                 [fieldName]: {
  //                   ...inputObj[fieldName],
  //                   value: numbersOnly,
  //                   phone_code:phoneCode
  //                 },
  //               };
  //             }
  //             return inputObj;
  //           });

  //           return updatedFormData;
  //         });
  //       }
  //     }
  //     else{
  //       console.log(fieldName);
  //       if (fieldName == 'po_number'){
  //         value = (value.substring(0, 6));
  //         value = value.replace(/[^0-9]/g, '');

  //       }
  //       if (fieldName == 'iban_number'){
  //         value = (value.substring(0, 25));
  //         value = value.replace(/[^0-9]/g, '');
  //       }
  //       if (fieldName == 'uuid_number'){
  //         value = value.replace(/[^0-9]/g, '');
  //       }
  //       setFormData((prevFormData) => {
  //       const updatedFormData = prevFormData.map((inputObj) => {
  //         const inputKey = Object.keys(inputObj)[0];
  //         if (inputKey === fieldName) {
  //           return {
  //             [fieldName]: {
  //               ...inputObj[fieldName],
  //               value: value,
  //             },
  //           };
  //         }
  //         return inputObj;
  //       });

  //       return updatedFormData;
  //     });
  //   }
  // };

  const handleSubmit = () => {
    dispatch(
      updateFormData({
        form_field: formData,
      })
    );

    // Validate form fields
    handleFormValidation();

    // Check if form is complete and if required fields are filled
    if (!isFormComplete()) {
      setError(true); // Set error state to indicate validation failure
      setButtonLoading(false);
      return; // Prevent further execution
    }

    setError(false); // Clear error state if form is complete and validated
    setButtonLoading(true);

    if (isOtpVerified) {
      // Perform form submission
      formSubmission(
        formData,
        dataState.formData.form_id,
        dataState.formData.sub_service_id
      ).then((res) => {
        if (res.status === 1) {
          dispatch(
            updateFormData({
              application_id: res.application_id,
            })
          );
          dispatch(
            updateData({ isPersonal: true, currentRoute: "Attachments" })
          );
          setButtonLoading(false);
        } else {
          setButtonLoading(false);
          setError(true);
          setFormError(res.message);
          setTimeout(() => {
            setError(false);
            setFormError("");
          }, 3000);
        }
      });
    } else {
      // Handle OTP verification
      dispatch(
        updateFormData({
          form_field: formData,
        })
      );
      setButtonLoading(false);
      setPhonemodal(true);
    }
  };

  const handleInputChange = (fieldName, value, phoneNumb, pCode) => {
    // Perform form validation on input change if error state is true
    if (error) {
      handleFormValidation();
    }

    if (phoneNumb) {
      // Handle phone number input changes
      const numbersOnly = (value ?? "").replace(/[^0-9]/g, "");
      if (numbersOnly.length < 15) {
        setFormData((prevFormData) => {
          const updatedFormData = prevFormData.map((inputObj) => {
            const inputKey = Object.keys(inputObj)[0];
            if (inputKey === fieldName) {
              return {
                [fieldName]: {
                  ...inputObj[fieldName],
                  value: numbersOnly,
                  phone_code: pCode,
                },
              };
            }
            return inputObj;
          });

          return updatedFormData;
        });
      }
    } else {
      // Handle other input changes (e.g., validation for different fields)
      if (fieldName === "po_number") {
        value = value.substring(0, 6).replace(/[^0-9]/g, "");
      } else if (fieldName === "iban_number") {
        value = value.substring(0, 25).replace(/[^0-9]/g, "");
      } else if (fieldName === "uuid_number") {
        value = value.replace(/[^0-9]/g, "");
      }

      setFormData((prevFormData) => {
        const updatedFormData = prevFormData.map((inputObj) => {
          const inputKey = Object.keys(inputObj)[0];
          if (inputKey === fieldName) {
            return {
              [fieldName]: {
                ...inputObj[fieldName],
                value: value,
              },
            };
          }
          return inputObj;
        });

        return updatedFormData;
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    // if (dataState.formData?.form_field?.length > 1) {
    //   setFormData(dataState.formData.form_field);
    //   setLoading(false);
    // } else {
    getServiceForm(dataState.formData.service_id).then((res) => {
      setFormData(res.data.fields);
      dispatch(
        updateFormData({
          form_id: res.data.id,
        })
      );
      setLoading(false);
    });
    // }
  }, []);
  const [same, setSame] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      updateNumber()
    }, 2000);
  }, [same, phone]);
  const updateNumber = () => {
    if (same && phone) {
      setFormData((prevFormData) => {
        const updatedFormData = prevFormData.map((inputObj) => {
          const inputKey = Object.keys(inputObj)[0];
          if (inputKey === "whatsapp_no") {
            console.log({ whatsapp_no: {
              ...inputObj["whatsapp_no"],
              value: phone,
              phone_code: phone_code,
            }})
            return {
              whatsapp_no: {
                ...inputObj["whatsapp_no"],
                value: phone,
                phone_code: phone_code,
              },
            };
          }
          return inputObj;
        });

        return updatedFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = prevFormData.map((inputObj) => {
          const inputKey = Object.keys(inputObj)[0];
          if (inputKey === "whatsapp_no") {
            return {
              whatsapp_no: {
                ...inputObj["whatsapp_no"],
                value: '',
                phone_code: '',
              },
            };
          }
          return inputObj;
        });

        return updatedFormData;
      });
    }
  }
  const scrollToInput = (ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth", // You can change this to 'auto' for instant scrolling
      });
      ref.current.focus();
    }
  };
  return (
    <>
      <Cover>
        {loading ? (
          <ComponentLoader />
        ) : (
          <Container>
            <FormCover>
              {formData.map((fieldData) => {
                const fieldName = Object.keys(fieldData)[0];
                let showError = fieldErrors[fieldName];
                const { field_type, label, validations, value, choice } =
                  fieldData[fieldName];

                if (field_type === "text") {
                  if (fieldName === "comment") {
                    return (
                      <>
                        <TextInput
                          key={fieldName}
                          // type={field_type}
                          label={label}
                          onChange={(e) =>
                            handleInputChange(fieldName, e.target.value)
                          }
                          value={fieldData[fieldName].value}
                          showError={
                            showError &&
                            fieldData[fieldName].value < 2 && (
                              <ErrorLabel>{showError}</ErrorLabel>
                            )
                          }
                        />
                      </>
                    );
                  } else {
                    if (choice?.length > 1) {
                      return (
                        <DropDown
                          key={fieldName}
                          type={field_type}
                          label={label}
                          // error = {error}
                          onChange={(e) =>
                            handleInputChange(fieldName, e.target.value)
                          }
                          value={fieldData[fieldName].value}
                          showError={
                            showError && <ErrorLabel>{showError}</ErrorLabel>
                          }
                          choices={choice}
                        />
                      );
                    }
                    return (
                      <InputBox
                        key={fieldName}
                        type={field_type}
                        label={label}
                        error={error}
                        onChange={(e) =>
                          handleInputChange(fieldName, e.target.value)
                        }
                        value={fieldData[fieldName].value}
                        showError={
                          fieldData[fieldName].value < 2 && (
                            <ErrorLabel>{showError}</ErrorLabel>
                          )
                        }
                      />
                    );
                  }
                } else if (
                  field_type === "number" &&
                  validations.phone_number == "true"
                ) {
                  return (
                    <PhoneInput
                      key={fieldName}
                      label={label}
                      onChange={(e) =>
                        handleInputChange(
                          fieldName,
                          e.target.value,
                          validations.phone_number,
                          fieldData[fieldName].phone_code,
                        )
                      }
                      maxLength={15}
                      value={fieldData[fieldName].value}
                      type="tel"
                      errorData={fieldData[fieldName].value}
                      setPhoneCode={(e) => {
                        handleInputChange(
                          fieldName,
                          fieldData[fieldName].value,
                          validations.phone_number,
                          e,
                        )
                        setPhoneCode(e)
                      }}
                      setSame={setSame}
                      same={same}
                      // error={error}
                      showError={
                        showError && <ErrorLabel>{showError}</ErrorLabel>
                      }
                    />
                  );
                } else if (validations.phone_number == "false") {
                  return (
                    <InputBox
                      key={fieldName}
                      type={field_type == "number" ? "tel" : field_type}
                      label={label}
                      error={error}
                      onChange={(e) =>
                        handleInputChange(fieldName, e.target.value)
                      }
                      value={fieldData[fieldName].value}
                      showError={
                        showError && <ErrorLabel>{showError}</ErrorLabel>
                      }
                    />
                  );
                }
              })}
            </FormCover>
            {error && <ErrorLabel>{errorMessage}</ErrorLabel>}
            {/* <ErrorSect>{formError}</ErrorSect> */}
            {formError && (
              <Snackbar
                open={error}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  {formError}
                </Alert>
              </Snackbar>
            )}
            <ContinueButton onClick={() => handleSubmit()} isActive={true}>
              {buttonLoading ? (
                <ButtonLoader />
              ) : (
                <>
                  CONTINUE <i className="ri-arrow-right-line"></i>
                </>
              )}
            </ContinueButton>
          </Container>
        )}
      </Cover>
      {phonemodal && (
        <PhoneNumberModal
          setPhonemodal={setPhonemodal}
          phonemodal={phonemodal}
          setOtpModal={setOtpModal}
          otpModal={otpModal}
        />
      )}
      {/* <OtpModal otpModal={otpModal} setOtpModal={setOtpModal}/> */}
    </>
  );
};

export default PersonalDetails;

const Cover = styled.div`
  background: #f7fcf9;
  border-radius: 40px;
  padding: 50px 20px;
  @media only screen and (max-width: 480px) {
    padding: 0px;
  }
`;

const Container = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 40px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  /* gap: 15px; */
  justify-content: flex-start;

  flex-wrap: wrap;
  box-shadow: 0px 3px 28px #0000000f;
  @media only screen and (max-width: 1200px) {
    width: 70%;
  }
  @media only screen and (max-width: 1080px) {
    width: 80%;
  }
  @media only screen and (max-width: 840px) {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding: 10px;
    padding-bottom: 40px;
  }
`;

const ContinueButton = styled.button`
  background-image: linear-gradient(to right, #00c0a5, #12c08b, #2dbf66);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 166px;

  font-size: 16px;
  outline: none;
  border: none;
  margin-top: 20px;
  cursor: ${(props) => (props.isActive ? "pointer" : "default")};
  padding: 5px 25px;
  font-family: "poppins_semibold";
  gap: 5px;
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-top: 45px;
  }
  &:focus {
    border: 1px solid #000; /* Remove the default focus outline */
  }
  i {
    font-weight: 600;
  }
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  pointer-events: ${(props) => (props.isActive ? "auto" : "none")};
`;

const FormCover = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const ErrorLabel = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  bottom: -25px;
  left: 0;
  padding: 5px 0px;
`;
const ErrorSect = styled.div`
  color: red;
  font-size: 12px;
`;
