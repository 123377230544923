import { Navigate, json, useNavigate } from "react-router-dom";
import { refreshToken } from "./auth";
import { Alert, Snackbar } from "@mui/material";
import { useErrorHandling } from "../utils/functions";
import useStore from "../utils/ZuStore";
import AlertHandle from "../components/includes/AlertHandle";
import axios from "axios";

const api = axios.create({
  baseURL: "https://api.ameronline.com/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

const getToken = () => {
  const local = JSON.parse(localStorage.getItem("state"));
  const token = local?.Login?.login?.data?.access;
  return token;
};

if (getToken()) {
  api.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
}

let isRefreshing = false;
let failedRequestsQueue = [];
api.interceptors.response.use(
  (response) => {
    const responseData = response.data;
    return responseData;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        localStorage.clear();
        originalRequest._retry = true;
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            // Get the new access token
            const newToken = await getToken();

            // Set the new access token in the API interceptor
            api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

            // Retry the request with the new access token
            return api(originalRequest);
          } catch (refreshError) {
            // Handle refresh token error
            const navigate = useNavigate();
            localStorage.clear();
            navigate("/");
          }
        } else {
          // Add the failed request to the queue
          failedRequestsQueue.push(originalRequest);
        }
      }
      if (error.response.status === 500) {
        const navigate = useNavigate();
        navigate("/");
      }
      if (error.response.status === 400) {
        const errorTrue = useStore.getState().errorTrue;
        errorTrue();
      }
      if (error.response.status === 404) {
        // const navigate = useNavigate();
        // navigate("/page-not-found");
        // window.location.href = window.location.pathname + '/page-not-found'
      }
    } else if (error.request) {
    } else {
      // throw new ApiError('An unknown error occurred.');
    }
    if (error.code === "ECONNABORTED") {
      // Handle timeout error here
    }
    return Promise.reject(error);
  }
);

export default api;
